<template>
  <div class="column">
    <div class="row justify-end">
      <div class="col col-auto q-pa-sm q-gutter-sm">
        <q-btn color="process" size="sm" outline @click.native="exportList('clotureAbonnement')">{{$t('processes.export_clotureAbonnement')}}</q-btn>
      </div>
      <div class="col col-auto q-pa-sm q-gutter-sm">
        <q-btn color="process" size="sm" outline @click.native="exportList()">{{$t('processes.export')}}</q-btn>
      </div>
      <div class="col col-auto q-pa-sm q-gutter-sm">
        <q-btn color="process" size="sm" :to="{ name: 'fermeture-gi-form' }" icon-right="add_circle">{{$t('processes.create_fermeture_gi')}}</q-btn>
      </div>
    </div>
    <q-table flat wrap-cells
      :data="fermetureGIProcesses"
      :columns="columns"
      row-key="id"
      :rows-per-page-options="rowsPerPageOptions"
      :pagination.sync="pagination"
      :loading="loading"
      :filter="filter"
      @request="onRequest"
      binary-state-sort>
      <q-tr slot="body" slot-scope="props" :props="props"
        @click.native="$router.push({ name: 'fermeture-gi-form', params: { id: props.row.id } })"
        class="cursor-pointer">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
          <div v-if="col.value" v-html="col.value" />
        </q-td>
      </q-tr>
    </q-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import download from 'downloadjs'

export default {
  name: 'fermetureGiList',

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    store.dispatch('processes/fetchFilters', 'fermetureGi')
  },

  meta () {
    return {
      title: 'Fermeture de GI'
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      fermetureGIProcesses: 'processes/getProcesses',
      filters: 'processes/getFilters',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    pagination: {
      ...mapGetters({ get: 'processes/getPagination' }),
      ...mapMutations({ set: 'processes/setPagination' })
    },
    filter () {
      return this.$route.meta.filter
    },

    columns () {
      console.log(this.fermetureGIProcesses, this.filters)
      if (this.fermetureGIProcesses.length === 0) return []
      else {
        const fields = ['wallets', 'date_fermeture_gi', 'statut']
        const sortable = []

        return fields.map(key => {
          let label = this.$t(`processes.fields.${key}.label`)
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'wallets') {
            formatFn = val => val ? val.map(wallet => wallet.NOMPTF).join(', ') : ''
          } else if (key === 'date_fermeture_gi') {
            formatFn = val => this.$formatDate(val)
          } else if (key === 'statut') {
            formatFn = val => val ? this.$t(`processes.status.${val}`) : ''
            align = 'center'
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: sortable.indexOf(key) >= 0,
            autoWidth: autoWidth
          }
        })
      }
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.onRequest({ pagination: this.pagination })
      },
      deep: true
    },
    '$route.meta.filter': {
      handler (newVal, oldVal) {
        this.onRequest({ pagination: this.pagination })
      },
      deep: true
    }
  },

  mounted () {
    // if (this.filters) {
    this.onRequest({ pagination: this.pagination })
    // }
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.fermeture_gi'), to: { name: 'fermeture-gi-en-cours' } },
      {}
    ])

    this.$store.commit('pages/setPageTitle', this.$t('processes.title.fermeture_gi'))
    this.$store.commit('pages/setTabs', [
      { name: 'fermeture-gi-en-cours', label: 'En cours', to: { name: 'fermeture-gi-en-cours' } },
      { name: 'fermeture-gi-archivés', label: 'Archivés', to: { name: 'fermeture-gi-archives' } }
    ])
  },

  methods: {
    onRequest ({ pagination, filter }) {
      this.$store.commit('processes/setPagination', pagination)
      this.loading = true

      let params = { ...pagination, filter: this.filter, typeProcess: 'fermetureGi' }
      if (this.filters) this.filters.forEach(filter => { params[filter.id] = filter.value })

      this.$store.dispatch('processes/fetchProcesses', params)
        .finally(() => { this.loading = false })
    },

    exportList (process) {
      let toExport = process || 'fermetureGi'
      this.$axios.get(`/api/processAgences/${toExport}/export`, {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'text/csv' }
      }).then(response => {
        download(response.data, `${toExport}.csv`)
      }).catch(this.$notifyError)
    }
  }
}
</script>
